import axios, { AxiosRequestConfig, Method } from "axios";
import { toInteger } from "lodash-es";
import { isAccessTokenExpired, refreshTokens, authInfo } from "@/core/auth";
import { createCustomError, getEnv } from "@/core/util";
import { createResponse, Response } from "@/core/api/response";
import { getParameter } from "@/core/util/env";
import REQUEST_TYPE from "@/core/api/request-types";

export const APIError = createCustomError("APIError");

let BASE_URL;
let TIMEOUT_MS;
let CONNECTOR_TIMEOUT_MS;
const retryCodes = [408, 502, 503, 504, 901];
const retryCount = 1;
const env = getEnv("ENVIRONMENT");
const HOST = env === "PRODUCTION" ? window.location.host : "";
const tenantId = getTenantId();

export function getTenantId(): string {
  if (env === "PRODUCTION") {
    try {
      return window.location.host.split(".")[0];
    } catch (e) {
      console.log("There is a problem on getting tenant id from url.");
      return "cyangate";
    }
  }
  return "cyangate";
}

export async function apiCall(
  method: Method,
  endpoint: string,
  body?: any,
  type: string = REQUEST_TYPE.STANDART,
  selectedOrgId?: string,
): Promise<Response> {
  if (!BASE_URL && !TIMEOUT_MS) {
    [BASE_URL, TIMEOUT_MS] = [await getParameter("REST_URL"), toInteger(await getParameter("TIMEOUT_MS"))];
  }
  if (!CONNECTOR_TIMEOUT_MS && type === REQUEST_TYPE.CONNECTOR) {
    CONNECTOR_TIMEOUT_MS = toInteger(await getParameter("CONNECTOR_REST_TIMEOUT_MS"));
  }

  const config: AxiosRequestConfig = {
    method,
    url: endpoint,
    baseURL: env === "PRODUCTION" ? `https://${HOST}${BASE_URL}` : BASE_URL,
    timeout: type === REQUEST_TYPE.CONNECTOR ? CONNECTOR_TIMEOUT_MS : TIMEOUT_MS,
    data: body,
    validateStatus: () => true,
    headers: authInfo.value ? {
      Authorization: authInfo.value?.accessToken,
      "X-TenantId": tenantId,
      "X-OrganizationId": selectedOrgId || authInfo.value?.organizationId,
    } : {
      "X-TenantId": tenantId,
    },
  };

  let response: Readonly<Response>;
  for (let i = 0; i < retryCount; i++) {
    try {
      const axiosResponse = await axios.request(config);
      response = createResponse(axiosResponse);
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }
      response = createResponse(err);
    }

    if (!isRetryResponse(response)) {
      break;
    }
  }

  if (isRetryResponse(response) || !isAccessTokenExpired(response)) {
    return response;
  }

  await refreshTokens();

  return apiCall(method, endpoint, body, type, selectedOrgId);
}

export async function apiCallParams(
  method: Method,
  endpoint: string,
  params?: any,
  type: string = REQUEST_TYPE.STANDART,
  selectedOrgId?: string,
): Promise<Response> {
  if (!BASE_URL && !TIMEOUT_MS) {
    [BASE_URL, TIMEOUT_MS] = [await getParameter("REST_URL"), toInteger(await getParameter("TIMEOUT_MS"))];
  }
  if (!CONNECTOR_TIMEOUT_MS && type === REQUEST_TYPE.CONNECTOR) {
    CONNECTOR_TIMEOUT_MS = toInteger(await getParameter("CONNECTOR_REST_TIMEOUT_MS"));
  }
  const config: AxiosRequestConfig = {
    method,
    url: endpoint,
    baseURL: env === "PRODUCTION" ? `https://${HOST}${BASE_URL}` : BASE_URL,
    timeout: type === REQUEST_TYPE.CONNECTOR ? CONNECTOR_TIMEOUT_MS : TIMEOUT_MS,
    params,
    validateStatus: () => true,
    headers: authInfo.value ? {
      Authorization: authInfo.value?.accessToken,
      "X-TenantId": tenantId,
      "X-OrganizationId": selectedOrgId || authInfo.value?.organizationId,
    } : {
      "X-TenantId": tenantId,
    },
  };

  let response: Readonly<Response>;
  for (let i = 0; i < retryCount; i++) {
    try {
      const axiosResponse = await axios.request(config);
      response = createResponse(axiosResponse);
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }
      response = createResponse(err);
    }

    if (!isRetryResponse(response)) {
      break;
    }
  }

  if (isRetryResponse(response) || !isAccessTokenExpired(response)) {
    return response;
  }

  await refreshTokens();

  return apiCallParams(method, endpoint, params, type, selectedOrgId);
}

function isRetryResponse(response: Response): boolean {
  return retryCodes.includes(response.code);
}

export async function getBaseUrl(): Promise<string> {
  try {
    if (!BASE_URL) {
      BASE_URL = await getParameter("REST_URL");
    }
    return env === "PRODUCTION" ? `https://${HOST}${BASE_URL}` : BASE_URL;
  } catch (e) {
    console.log("There is a problem on getting base url.");
  }
  return "";
}
